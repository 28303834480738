import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import "@fontsource/inter";
import "@fontsource/inter/600.css";
import styled from "styled-components";

import {
  fetchComments,
  fetchProductItemDetails,
  fetchProductList,
  fetchProductPredictedUnits,
  fetchProductProfitability,
  fetchProductRanks,
  fetchProductSales,
  fetchProductUnitEconomics,
  fetchProductsLongTermStorageData,
  storeAnalyticsSelector,
} from "../../../../../redux/slices/store_analytics";
import {
  FiltersValueTextField,
  StyledSelect,
} from "../StoreProductDetails/BusinessReportAdvanced/styles";
import {
  ColumnTitle,
  EmptyProductListMsg,
  TableStyled,
} from "../StoreProducts/styles";

import { ProductRowDetailed } from "./AllProductsTableRow";
import ExtendedSummaryCard from "./ExtendedSummaryCard";

const CustomCheckbox = styled(Checkbox)`
  & .MuiSvgIcon-root {
    font-size: 22px;
  }
`;

const CustomInput = styled(FormControlLabel)`
  margin-left: 5px;
  & .MuiFormControlLabel-label {
    font: 300 12px "Inter", sans-serif;
  }
`;

const TableCustom = ({ style, value }) => {
  // const [lastFetchedProduct, setLastFetchedProduct] = useState(null);

  var columns = [
    "All store Products in the last 30 days",
    // "Unit economics report",
    // "FBA Quantity",
    // "3PL Quantity",
    // "Units on the way",
  ];

  const dispatch = useDispatch();

  const {
    productItemDetailsList,
    productItemDetailsLoading,
    productListData,
    productListLoading,
    productSalesList,
    productSalesLoading,
    productUnitEconomicsList,
    productUnitEconomicsLoading,
    productProfitabilityList,
    productProfitabilityLoading,
    productRanksList,
    productRanksLoading,
    productLongTermStorageData,
    productLongTermStorageDataLoading,
    productPredictedUnitsList,
    productPredictedUnitsLoading,
    productCommentsData,
  } = useSelector(storeAnalyticsSelector);

  const filterAttributes = [
    "Days of supply",
    "Unit economics",
    "Realistic stock",
    "Product velocity",
    "Velocity change",
  ];
  const filterActions = ["greater than", "less than", "equal to"];

  const [currStore, setCurrStore] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [openAllRows, setOpenAllRows] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const filterAttributeRef = useRef(null);
  const filterActionRef = useRef(null);
  const filterValueRef = useRef(null);
  const [filterFieldsResetFlag, setFilterFieldsResetFlag] = useState(false);
  const [filterErrors, setFilterErrors] = useState({
    filterAttribute: false,
    filterAction: false,
    filterValue: false,
  });

  useEffect(() => {
    if (value.store !== "all" && value.store !== currStore) {
      console.log("dispatching only once for store " + value.store);
      dispatch(
        fetchProductList({
          store: value.store,
          duration: value.compareConditions.current,
          hierarchy: "hierarchy",
        })
      );
      dispatch(
        fetchProductItemDetails({
          store: value.store,
          level: "hirearchy",
        })
      );
      dispatch(
        fetchProductSales({
          store: value.store,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductRanks({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductUnitEconomics({
          store: value.store,
          level: "child",
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductProfitability({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      dispatch(
        fetchProductsLongTermStorageData({
          store: value.store,
        })
      );
      if (new Date().getMonth() >= 10 && new Date().getMonth() <= 12) {
        dispatch(
          fetchProductPredictedUnits({
            store: value.store,
            start_date: value.compareConditions.current.start_date,
            end_date: value.compareConditions.current.end_date,
          })
        );
      }
      dispatch(
        fetchComments({
          store: value.store,
          start_date: value.compareConditions.current.start_date,
          end_date: value.compareConditions.current.end_date,
        })
      );
      setCurrStore(value.store);
    }
  }, [value, currStore, dispatch]); // table rerender on compare conditions change

  useEffect(() => {
    if (Object.keys(productItemDetailsList).length > 0) {
      setProductsList(productItemDetailsList);
    } else {
      setProductsList([]);
    }
  }, [productItemDetailsList]);

  const relevantProductData = (product) => {
    if (!product.children) {
      return (
        productListData &&
        product.sku.split(",").map((sku) => {
          return { sku: sku.trim(), ...productListData[sku.trim()] };
        })
      );
    } else {
      return (
        productListData &&
        Object.entries(productListData)
          .map(([key, value]) => {
            if (
              product.children.map((c) => c.child_asin).includes(value.asin)
            ) {
              return { ...value, sku: key };
            } else {
              return null;
            }
          })
          .filter(Boolean)
      );
    }
  };

  const relevantCommentsData = (product) => {
    let emptyCommentsData = {
      [product.child_asin]: [],
    };
    if (!productCommentsData) {
      return emptyCommentsData;
    }
    let parentChildAsins = [];
    if (product.children) {
      parentChildAsins = product.children.map((c) => c.child_asin);
      parentChildAsins.push(product.parent_asin);
    } else {
      parentChildAsins.push(product.child_asin);
    }

    return Object.fromEntries(
      Object.entries(productCommentsData).filter(([key]) =>
        parentChildAsins.includes(key)
      )
    );
  };

  const relevantPredictedUnitsData = (product) => {
    let emptyPredictedUnitsData = {
      [product.child_asin]: {},
      dates: [],
    };
    if (!productPredictedUnitsList) {
      return emptyPredictedUnitsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productPredictedUnitsList)
      .filter(([key]) => childAsins.includes(key) || key === "dates")
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyPredictedUnitsData);
  };

  const relevantRanksData = (product) => {
    let emptyRanksData = {
      [product.child_asin]: {},
    };
    if (!productRanksList) {
      return emptyRanksData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productRanksList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyRanksData);
  };

  const relevantProfitabilityData = (product) => {
    let emptyProfitabilityData = {
      [product.child_asin]: {},
    };
    if (!productProfitabilityList) {
      return emptyProfitabilityData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productProfitabilityList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyProfitabilityData);
  };

  const relevantSalesData = (product) => {
    let emptySalesData = {
      [product.child_asin]: { dates: [], units: [], avg_price: [], sales: [] },
    };
    if (!productSalesList) {
      return emptySalesData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    // return productSalesList.filter((item) => item.asin === asin);
    return Object.entries(productSalesList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptySalesData);
  };

  const relevantEconomicsData = (product) => {
    let emptyEconomicsData = {
      [product.child_asin]: {
        date: "",
        price: 0,
        cogs: 0,
        fulfillment_fee: 0,
        referal_fee: 0,
      },
    };
    if (!productUnitEconomicsList) {
      return emptyEconomicsData;
    }
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productUnitEconomicsList)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyEconomicsData);
  };

  const relevantLongTermStorageData = (product) => {
    let emptyLongTermStorageData = {};
    let childAsins = [];
    if (product.children) {
      childAsins = product.children.map((c) => c.child_asin);
    } else {
      childAsins.push(product.child_asin);
    }

    return Object.entries(productLongTermStorageData)
      .filter(([key]) => childAsins.includes(key))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, emptyLongTermStorageData);
  };

  const isDataLoading =
    productItemDetailsLoading ||
    productListLoading ||
    productSalesLoading ||
    productUnitEconomicsLoading ||
    productProfitabilityLoading ||
    productLongTermStorageDataLoading ||
    productRanksLoading ||
    productPredictedUnitsLoading;

  const resetFilterFields = () => {
    filterAttributeRef.current.value = "";
    filterActionRef.current.value = "";
    filterValueRef.current.value = "";
    setFilterFieldsResetFlag(!filterFieldsResetFlag);
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  const addFilter = () => {
    const filterFieldsErrors = {
      filterAttribute: !filterAttributeRef.current.value,
      filterAction: !filterActionRef.current.value,
      filterValue: !filterValueRef.current.value,
    };
    if (
      filterFieldsErrors.filterAttribute ||
      filterFieldsErrors.filterAction ||
      filterFieldsErrors.filterValue
    ) {
      setFilterErrors(filterFieldsErrors);
      return;
    }

    setOpenAllRows(true);
    const newFilter = {
      attr: filterAttributeRef.current.value,
      action: filterActionRef.current.value,
      value: parseFloat(filterValueRef.current.value),
    };
    setAppliedFilters([...appliedFilters, newFilter]);
    resetFilterFields();
  };

  const removeFilter = (indexToRemove) => {
    setAppliedFilters(
      appliedFilters.filter((_, index) => index !== indexToRemove)
    );
    setFilterErrors({
      filterAttribute: false,
      filterAction: false,
      filterValue: false,
    });
  };

  const getReportUnitEconomicsData = (
    productUnitEconomicsList,
    productItemDetailsList
  ) => {
    let data = Object.entries(productUnitEconomicsList).map(([asin, data]) => {
      const parentAsin =
        productItemDetailsList.find((item) =>
          item.children?.some((child) => child.child_asin === asin)
        )?.parent_asin || "N/A"; // Fallback value if parent_asin is not found

      return {
        parent_asin: parentAsin,
        asin,
        ...data,
      };
    });
    return data;
  };

  const getReportUnitEconomicsHeaders = () => {
    const headers = Object.keys(
      Object.values(productUnitEconomicsList)?.[0] || {}
    );
    return ["parent_asin", "asin", ...headers];
  };

  const getDateStringIndex = (daysAgo) => {
    const now = new Date();
    now.setDate(now.getDate() - daysAgo);
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${month}-${day}`;
  };

  const getTotalProfit = (daysAgo) => {
    return Math.round(getTotalDailyProfit(daysAgo)).toLocaleString() + "$";
  };

  const getTotalDailyProfit = (daysAgo) => {
    const currDateString = getDateStringIndex(daysAgo);
    return productProfitabilityList
      ? Object.values(productProfitabilityList).reduce((acc, curr) => {
          const dayValues = curr[currDateString];
          const sumDaily = dayValues
            ? dayValues["orderedProductSales"] +
              dayValues["extra_fee"] +
              Object.entries(dayValues)
                .filter(
                  ([k]) =>
                    k !== "asin" &&
                    k !== "orderedProductSales" &&
                    k !== "extra_fee"
                )
                .reduce((acc, [, val]) => acc + val * -1, 0)
            : 0;
          return acc + sumDaily;
        }, 0)
      : 0;
  };

  const getTotalMargin = (daysAgo) => {
    const sales = getTotalDailyValue(daysAgo, "sales");
    const profit = getTotalDailyProfit(daysAgo);
    return Math.round((profit / sales) * 100).toLocaleString() + "%" || 0;
  };

  const getTotalDailyProfitElement = (daysAgo, element) => {
    const currDateString = getDateStringIndex(daysAgo);
    return productProfitabilityList
      ? Object.values(productProfitabilityList).reduce((acc, curr) => {
          const dayValues = curr[currDateString];
          return acc + (dayValues ? dayValues[element] : 0);
        }, 0)
      : 0;
  };

  const getTotalProfitElement = (daysAgo, element) => {
    const value = getTotalDailyProfitElement(daysAgo, element);
    if (element === "extra_fee") {
      return (value >= 0 ? "+" : "") + Math.round(value).toLocaleString() + "$";
    } else {
      return Math.round(value * -1).toLocaleString() + "$";
    }
  };

  const getTotalDailyValue = (daysAgo, metric) => {
    return productSalesList
      ? Object.values(productSalesList).reduce(
          (acc, curr) => acc + curr[metric][curr[metric].length - 1 - daysAgo],
          0
        )
      : 0;
  };

  const getTotalString = (value, metric) => {
    if (metric === "units") {
      return Math.round(value).toLocaleString();
    } else if (metric === "sales") {
      return Math.round(value).toLocaleString() + "$";
    } else if (metric === "ppc") {
      return (Math.round(value) * -1).toLocaleString() + "$";
    } else {
      return 0;
    }
  };

  const getTotalDaily = (daysAgo, metric) => {
    const value = getTotalDailyValue(daysAgo, metric);
    return getTotalString(value, metric);
  };

  const getTotalForPeriod = (metric, days) => {
    let totalMetric = 0;
    for (let i = 1; i <= days; i++) {
      totalMetric += getTotalDailyValue(i, metric);
    }
    return getTotalString(totalMetric, metric);
  };

  const getTotalProfitForPeriod = (days) => {
    let totalProfit = 0;
    for (let i = 1; i <= days; i++) {
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round(totalProfit).toLocaleString() + "$";
  };

  const getTotalProfitElementForPeriod = (days, element) => {
    let totalElement = 0;
    for (let i = 1; i <= days; i++) {
      totalElement += getTotalDailyProfitElement(i, element);
    }
    if (element === "extra_fee") {
      return (
        (totalElement >= 0 ? "+" : "") +
        Math.round(totalElement).toLocaleString() +
        "$"
      );
    } else {
      return Math.round(totalElement * -1).toLocaleString() + "$";
    }
  };

  const getTotalMarginForPeriod = (days) => {
    let totalSales = 0;
    let totalProfit = 0;
    for (let i = 1; i <= days; i++) {
      totalSales += getTotalDailyValue(i, "sales");
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round((totalProfit / totalSales) * 100).toLocaleString() + "%";
  };

  const getBreakdownHTMLstring = (period, func) => {
    return `
      <div style="display: flex">
        <div style="width: 100%">Cogs: </div>
        <div>${func(period, "cogs_cost")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Refunds: </div>
        <div>${func(period, "refunded_amount")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Promotions: </div>
        <div>${func(period, "item_promotion_discount")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Fees: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Fulfillments: </div>
        <div>${func(period, "fulfillment_fee")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Referals: </div>
        <div>${func(period, "referal_fee")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Extras: </div>
        <div>${func(period, "extra_fee")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">PPC: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Product: </div>
        <div>${func(period, "product_cost")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Brand: </div>
        <div>${func(period, "brand_cost")}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Display: </div>
        <div>${func(period, "display_cost")}</div>
      </div>
    `;
  };

  return (
    <React.Fragment>
      {/* summary boxes */}
      <Grid
        container
        spacing={1}
        style={{ "margin-bottom": "20px", "margin-top": "10px" }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={2.4}>
          <ExtendedSummaryCard
            title="Today"
            valueUnits={getTotalDaily(0, "units")}
            valueProfit={getTotalProfit(0)}
            valueSales={getTotalDaily(0, "sales")}
            valuePPC={getTotalDaily(0, "ppc")}
            subTitleOne="Breakdown"
            totalValueOne={" "}
            showCollapseValueOne={true}
            valueOneExpended={getBreakdownHTMLstring(0, getTotalProfitElement)}
            subTitleTwo="Net margin"
            totalValueTwo={getTotalMargin(0)}
            isLoading={isDataLoading}
          />
        </Grid>
        <Grid item xs={2.4}>
          <ExtendedSummaryCard
            title="Yesterday"
            valueUnits={getTotalDaily(1, "units")}
            valueProfit={getTotalProfit(1)}
            valueSales={getTotalDaily(1, "sales")}
            valuePPC={getTotalDaily(1, "ppc")}
            subTitleOne="Breakdown"
            totalValueOne={" "}
            showCollapseValueOne={true}
            valueOneExpended={getBreakdownHTMLstring(1, getTotalProfitElement)}
            subTitleTwo="Net margin"
            totalValueTwo={getTotalMargin(1)}
            isLoading={isDataLoading}
          />
        </Grid>
        <Grid item xs={2.4}>
          <ExtendedSummaryCard
            title="2 days ago"
            valueUnits={getTotalDaily(2, "units")}
            valueProfit={getTotalProfit(2)}
            valueSales={getTotalDaily(2, "sales")}
            valuePPC={getTotalDaily(2, "ppc")}
            subTitleOne="Breakdown"
            totalValueOne={" "}
            showCollapseValueOne={true}
            valueOneExpended={getBreakdownHTMLstring(2, getTotalProfitElement)}
            subTitleTwo="Net margin"
            totalValueTwo={getTotalMargin(2)}
            isLoading={isDataLoading}
          />
        </Grid>
        <Grid item xs={2.4}>
          <ExtendedSummaryCard
            title="7 days"
            valueUnits={getTotalForPeriod("units", 7)}
            valueProfit={getTotalProfitForPeriod(7)}
            valueSales={getTotalForPeriod("sales", 7)}
            valuePPC={getTotalForPeriod("ppc", 7)}
            subTitleOne="Breakdown"
            totalValueOne={" "}
            showCollapseValueOne={true}
            valueOneExpended={getBreakdownHTMLstring(
              7,
              getTotalProfitElementForPeriod
            )}
            subTitleTwo="Net margin"
            totalValueTwo={getTotalMarginForPeriod(7)}
            isLoading={isDataLoading}
          />
        </Grid>
        <Grid item xs={2.4}>
          <ExtendedSummaryCard
            title="30 days"
            valueUnits={getTotalForPeriod("units", 30)}
            valueProfit={getTotalProfitForPeriod(30)}
            valueSales={getTotalForPeriod("sales", 30)}
            valuePPC={getTotalForPeriod("ppc", 30)}
            subTitleOne="Breakdown"
            totalValueOne={" "}
            showCollapseValueOne={true}
            valueOneExpended={getBreakdownHTMLstring(
              30,
              getTotalProfitElementForPeriod
            )}
            subTitleTwo="Net margin"
            totalValueTwo={getTotalMarginForPeriod(30)}
            isLoading={isDataLoading}
          />
        </Grid>
      </Grid>
      {/* table filters */}
      <div style={{ "margin-bottom": "10px" }}>
        <CustomInput
          control={
            <CustomCheckbox
              checked={openAllRows}
              onChange={(e) => setOpenAllRows(e.target.checked)}
            />
          }
          label={"Expand all products"}
        />
        <FormControl error={filterErrors.filterAttribute}>
          <StyledSelect
            ref={filterAttributeRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Attribue";
              }
              return selected;
            }}
            inputProps={{ ref: filterAttributeRef }}
            key={`attribute-${filterFieldsResetFlag}`}
            error={filterErrors.filterAttribute}
          >
            {filterAttributes.map((attr, index) => (
              <MenuItem key={index} value={attr}>
                {attr}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAttribute && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterAction}>
          <StyledSelect
            ref={filterActionRef}
            disabled={isDataLoading || currStore.length === 0}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return "Select Action";
              }
              return selected;
            }}
            inputProps={{ ref: filterActionRef }}
            key={`action-${filterFieldsResetFlag}`}
            error={filterErrors.filterAction}
          >
            {filterActions.map((action, index) => (
              <MenuItem key={index} value={action}>
                {action}
              </MenuItem>
            ))}
          </StyledSelect>
          {filterErrors.filterAction && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl error={filterErrors.filterValue} style={{ width: "10%" }}>
          <FiltersValueTextField
            label="Enter Value"
            variant="outlined"
            size="small"
            disabled={isDataLoading || currStore.length === 0}
            inputProps={{ ref: filterValueRef }}
            key={`value-${filterFieldsResetFlag}`}
            error={filterErrors.filterValue}
          />
          {filterErrors.filterValue && (
            <FormHelperText style={{ color: "#d32f2f" }}>
              This field is required
            </FormHelperText>
          )}
        </FormControl>
        <Button
          style={{ "margin-left": "10px" }}
          onClick={addFilter}
          disabled={isDataLoading || currStore.length === 0}
        >
          Add filter
        </Button>
      </div>
      {appliedFilters.length > 0 && (
        <div style={{ "margin-left": "16px", "margin-top": "10px" }}>
          Currently applied filters:
          <ul>
            {appliedFilters.map((filter, index) => {
              return (
                <li key={index}>
                  <div>
                    {filter.attr} {filter.action} {filter.value}
                    <Button onClick={() => removeFilter(index)}>X</Button>
                  </div>
                  {appliedFilters.length > 1 &&
                    index !== appliedFilters.length - 1 &&
                    "and"}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {/* list of all products in a table */}
      <TableStyled style={style} component={Paper}>
        {isDataLoading ? (
          <>
            {[1, 2, 3, 4, 5].map((item) => (
              <Skeleton
                key={item}
                width={"97%"}
                sx={{ mt: 5, ml: 5 }}
                variant="rectangular"
                height={15}
              />
            ))}
          </>
        ) : (
          <Table>
            {/* table headers */}
            <TableHead>
              <TableRow
                style={{
                  display: "flex",
                }}
              >
                {columns.map((e, i) => (
                  <TableCell
                    key={i}
                    style={{
                      padding: "13px",
                      whiteSpace: "nowrap",
                      borderLeft:
                        e === "Conversion Rate" && "1px solid #e0e0e0",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "#64748B",
                        position: "relative",
                      }}
                      data-testid="table_header"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ColumnTitle>{e}</ColumnTitle>
                      </div>
                    </div>
                  </TableCell>
                ))}
                {productsList.length !== 0 ? (
                  <TableCell
                    style={{
                      display: "flex",
                      color: "#64748B",
                      justifyContent: "flex-end",
                      flexGrow: 1,
                    }}
                  >
                    <CSVLink
                      data={getReportUnitEconomicsData(
                        productUnitEconomicsList,
                        productItemDetailsList
                      )}
                      filename={value.store + "_unit_economics_report.csv"}
                      headers={getReportUnitEconomicsHeaders(
                        productUnitEconomicsList
                      )}
                      disabled={isDataLoading}
                    >
                      <DownloadIcon
                        style={{ marginRight: "5px" }}
                        disabled={isDataLoading}
                      />
                    </CSVLink>
                    {"Unit Economics report"}
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            {/* table body products - productListData */}
            {productsList.length !== 0 ? (
              <TableBody>
                {productsList.map((product, index) => (
                  <ProductRowDetailed
                    key={index}
                    store={value.store}
                    sku={product.sku}
                    product={product}
                    compareConditions={value.compareConditions}
                    leaf={value.periodicity}
                    openAllRows={openAllRows}
                    inventoryData={relevantProductData(product)}
                    salesData={relevantSalesData(product)}
                    unitEconomicsData={relevantEconomicsData(product)}
                    profitabilityData={relevantProfitabilityData(product)}
                    ranksData={relevantRanksData(product)}
                    longTermStorageData={relevantLongTermStorageData(product)}
                    predictedUnitsData={relevantPredictedUnitsData(product)}
                    comments={relevantCommentsData(product)}
                    filters={appliedFilters}
                    setParentShow={null}
                    parentApiRef={null}
                    parentScrollHandler={null}
                  />
                ))}
              </TableBody>
            ) : null}
          </Table>
        )}
        {!productItemDetailsLoading && productItemDetailsList.length === 0 && (
          <EmptyProductListMsg>
            {value === "all"
              ? "Please select a store first!"
              : "No products available"}
          </EmptyProductListMsg>
        )}
      </TableStyled>
    </React.Fragment>
  );
};

export default TableCustom;
