import { Grid } from "@mui/material";
import React from "react";

import ExtendedSummaryCard from "./ExtendedSummaryCard";

const Summaries = ({
  productSalesList,
  productProfitabilityList,
  isDataLoading,
}) => {
  const getDateStringIndex = (daysAgo) => {
    const now = new Date();
    now.setDate(now.getDate() - daysAgo);
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${month}-${day}`;
  };

  const getTotalProfit = (daysAgo) => {
    return Math.round(getTotalDailyProfit(daysAgo)).toLocaleString() + "$";
  };

  const getTotalDailyProfit = (daysAgo) => {
    const currDateString = getDateStringIndex(daysAgo);
    return productProfitabilityList
      ? Object.values(productProfitabilityList).reduce((acc, curr) => {
          const dayValues = curr[currDateString];
          const sumDaily = dayValues
            ? dayValues["orderedProductSales"] +
              dayValues["extra_fee"] +
              Object.entries(dayValues)
                .filter(
                  ([k]) =>
                    k !== "asin" &&
                    k !== "orderedProductSales" &&
                    k !== "extra_fee"
                )
                .reduce((acc, [, val]) => acc + val * -1, 0)
            : 0;
          return acc + sumDaily;
        }, 0)
      : 0;
  };

  const getTotalMargin = (daysAgo) => {
    const sales = getTotalDailyValue(daysAgo, "sales");
    const profit = getTotalDailyProfit(daysAgo);
    return Math.round((profit / sales) * 100).toLocaleString() + "%" || 0;
  };

  const getTotalDailyProfitElement = (daysAgo, element) => {
    const currDateString = getDateStringIndex(daysAgo);
    return productProfitabilityList
      ? Object.values(productProfitabilityList).reduce((acc, curr) => {
          const dayValues = curr[currDateString];
          return acc + (dayValues ? dayValues[element] : 0);
        }, 0)
      : 0;
  };

  const getTotalProfitElement = (daysAgo, element) => {
    const value = getTotalDailyProfitElement(daysAgo, element);
    if (element === "extra_fee") {
      return (value >= 0 ? "+" : "") + Math.round(value).toLocaleString() + "$";
    } else {
      return Math.round(value * -1).toLocaleString() + "$";
    }
  };

  const getTotalDailyValue = (daysAgo, metric) => {
    return productSalesList
      ? Object.values(productSalesList).reduce(
          (acc, curr) => acc + curr[metric][curr[metric].length - 1 - daysAgo],
          0
        )
      : 0;
  };

  const getTotalString = (value, metric) => {
    if (metric === "units") {
      return Math.round(value).toLocaleString();
    } else if (metric === "sales") {
      return Math.round(value).toLocaleString() + "$";
    } else if (metric === "ppc") {
      return (Math.round(value) * -1).toLocaleString() + "$";
    } else {
      return 0;
    }
  };

  const getTotalDaily = (daysAgo, metric) => {
    const value = getTotalDailyValue(daysAgo, metric);
    return getTotalString(value, metric);
  };

  const getTotalForPeriod = (metric, days, includeLastDay = false) => {
    let totalMetric = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalMetric += getTotalDailyValue(i, metric);
    }
    return getTotalString(totalMetric, metric);
  };

  const getTotalProfitForPeriod = (days, includeLastDay = false) => {
    let totalProfit = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round(totalProfit).toLocaleString() + "$";
  };

  const getTotalProfitElementForPeriod = (
    days,
    element,
    includeLastDay = false
  ) => {
    let totalElement = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalElement += getTotalDailyProfitElement(i, element);
    }
    if (element === "extra_fee") {
      return (
        (totalElement >= 0 ? "+" : "") +
        Math.round(totalElement).toLocaleString() +
        "$"
      );
    } else {
      return Math.round(totalElement * -1).toLocaleString() + "$";
    }
  };

  const getTotalMarginForPeriod = (days, includeLastDay = false) => {
    let totalSales = 0;
    let totalProfit = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalSales += getTotalDailyValue(i, "sales");
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round((totalProfit / totalSales) * 100).toLocaleString() + "%";
  };

  const getBreakdownHTMLstring = (period, func, includeLastDay) => {
    return `
      <div style="display: flex">
        <div style="width: 100%">Cogs: </div>
        <div>${func(period, "cogs_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Refunds: </div>
        <div>${func(period, "refunded_amount", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Promotions: </div>
        <div>${func(period, "item_promotion_discount", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Fees: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Fulfillments: </div>
        <div>${func(period, "fulfillment_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Referals: </div>
        <div>${func(period, "referal_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Extras: </div>
        <div>${func(period, "extra_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">PPC: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Product: </div>
        <div>${func(period, "product_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Brand: </div>
        <div>${func(period, "brand_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Display: </div>
        <div>${func(period, "display_cost", includeLastDay)}</div>
      </div>
    `;
  };

  const getDaysPassedInMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const diffTime = Math.abs(today - startOfMonth);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const daysPassedInMonth = getDaysPassedInMonth();

  return (
    <Grid
      container
      spacing={1}
      style={{ "margin-bottom": "20px", "margin-top": "10px" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={2.4}>
        <ExtendedSummaryCard
          title="Today"
          valueUnits={getTotalDaily(0, "units")}
          valueProfit={getTotalProfit(0)}
          valueSales={getTotalDaily(0, "sales")}
          valuePPC={getTotalDaily(0, "ppc")}
          subTitleOne="Breakdown"
          totalValueOne={" "}
          showCollapseValueOne={true}
          valueOneExpended={getBreakdownHTMLstring(0, getTotalProfitElement)}
          subTitleTwo="Net margin"
          totalValueTwo={getTotalMargin(0)}
          isLoading={isDataLoading}
        />
      </Grid>
      <Grid item xs={2.4}>
        <ExtendedSummaryCard
          title="Yesterday"
          valueUnits={getTotalDaily(1, "units")}
          valueProfit={getTotalProfit(1)}
          valueSales={getTotalDaily(1, "sales")}
          valuePPC={getTotalDaily(1, "ppc")}
          subTitleOne="Breakdown"
          totalValueOne={" "}
          showCollapseValueOne={true}
          valueOneExpended={getBreakdownHTMLstring(1, getTotalProfitElement)}
          subTitleTwo="Net margin"
          totalValueTwo={getTotalMargin(1)}
          isLoading={isDataLoading}
        />
      </Grid>
      <Grid item xs={2.4}>
        <ExtendedSummaryCard
          title="2 days ago"
          valueUnits={getTotalDaily(2, "units")}
          valueProfit={getTotalProfit(2)}
          valueSales={getTotalDaily(2, "sales")}
          valuePPC={getTotalDaily(2, "ppc")}
          subTitleOne="Breakdown"
          totalValueOne={" "}
          showCollapseValueOne={true}
          valueOneExpended={getBreakdownHTMLstring(2, getTotalProfitElement)}
          subTitleTwo="Net margin"
          totalValueTwo={getTotalMargin(2)}
          isLoading={isDataLoading}
        />
      </Grid>
      <Grid item xs={2.4}>
        <ExtendedSummaryCard
          title="7 days"
          valueUnits={getTotalForPeriod("units", 7)}
          valueProfit={getTotalProfitForPeriod(7)}
          valueSales={getTotalForPeriod("sales", 7)}
          valuePPC={getTotalForPeriod("ppc", 7)}
          subTitleOne="Breakdown"
          totalValueOne={" "}
          showCollapseValueOne={true}
          valueOneExpended={getBreakdownHTMLstring(
            7,
            getTotalProfitElementForPeriod,
            false
          )}
          subTitleTwo="Net margin"
          totalValueTwo={getTotalMarginForPeriod(7)}
          isLoading={isDataLoading}
        />
      </Grid>
      <Grid item xs={2.4}>
        <ExtendedSummaryCard
          title="Month to date"
          valueUnits={getTotalForPeriod("units", daysPassedInMonth, true)}
          valueProfit={getTotalProfitForPeriod(daysPassedInMonth, true)}
          valueSales={getTotalForPeriod("sales", daysPassedInMonth, true)}
          valuePPC={getTotalForPeriod("ppc", daysPassedInMonth, true)}
          subTitleOne="Breakdown"
          totalValueOne={" "}
          showCollapseValueOne={true}
          valueOneExpended={getBreakdownHTMLstring(
            daysPassedInMonth,
            getTotalProfitElementForPeriod,
            true
          )}
          subTitleTwo="Net margin"
          totalValueTwo={getTotalMarginForPeriod(daysPassedInMonth, true)}
          isLoading={isDataLoading}
        />
      </Grid>
    </Grid>
  );
};

export default Summaries;
