import {
  Box,
  Button,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridFooterContainer,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { eachDayOfInterval, format, parseISO } from "date-fns";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  addSearchTermClear,
  advanceBusinessReportSelector,
  createSearchTerm,
  updateSearchTermsRelevancy,
} from "../../../../../../redux/slices/advance_business_report";
import { storeAnalyticsSelector } from "../../../../../../redux/slices/store_analytics";
import { isNonEmptyObject } from "../../ProductSKUDashboard/HistoricalCharts/helpers";

const CompText = styled(Typography)`
  font-family: "Inter", sans-serif;
  border: none;
  align-items: center;
  margin-left: 10px;
  color: #171717;
  padding-right: 20px;
  display: inline;
  flex-wrap: wrap;
  .text {
    color: #000000;
    font-weight: bold;
  }
`;

const CompImageItem = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  vertical-align: bottom;
`;

const DataTable = styled(DataGridPro)({
  ".MuiDataGrid-main>div:nth-child(3)": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 !important",
  },
  "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeaderDraggableContainer":
    {
      position: "absolute",
      left: "-5px",
    },
  "& .MuiDataGrid-pinnedColumns": {
    backgroundColor: "white",
  },
  "& .noFocusBorder:focus": {
    outline: "none",
  },
  "& .noFocusBorder:focus-within": {
    outline: "none",
  },
  '& .MuiDataGrid-columnHeader[data-field="relevancy"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      justifyContent: "center",
      marginTop: "15px",
      lineHeight: "0px",
    },
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders": {
    minHeight: "70px!important",
  },
});

const getBackgroundColor = (position) => {
  if (position >= 1 && position <= 5) return "rgba(76, 175, 80, 0.5)"; // Strong Green
  if (position >= 6 && position <= 10) return "rgba(76, 175, 80, 0.2)"; // Light Green
  if (position >= 11 && position <= 20) return "rgba(255, 235, 59, 0.2)"; // Yellow
  if (position >= 21 && position <= 29) return "rgba(255, 152, 0, 0.2)"; // Orange
  if (position >= 30) return "rgba(244, 67, 54, 0.2)"; // Red
  return "transparent";
};

const CustomFooterContainer = styled(GridFooterContainer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  height: "auto",
}));

const AddKeywordFooter = () => {
  const dispatch = useDispatch();
  const { store } = useSelector(storeAnalyticsSelector);
  const { editSearchTermsLoading, addNewKeywordResponse } = useSelector(
    advanceBusinessReportSelector
  );
  const [newKeywordValue, setNewKeywordValue] = useState("");
  const [emptyKeywordError, setEmptyKeywordError] = useState(false);

  const handleNewKeywordChange = (e) => {
    setNewKeywordValue(e.target.value);
    if (emptyKeywordError) {
      setEmptyKeywordError(false);
    }
    if (isNonEmptyObject(addNewKeywordResponse)) {
      dispatch(addSearchTermClear());
    }
  };

  const handleAddNewKeyword = () => {
    if (newKeywordValue.trim().length === 0) {
      setEmptyKeywordError(true);
      return;
    }
    dispatch(
      createSearchTerm({
        collection_name: store,
        search_terms: [newKeywordValue],
      })
    );
  };

  return (
    <CustomFooterContainer>
      <div style={{ marginLeft: "5px" }}>
        <TextField
          value={newKeywordValue}
          size="small"
          onChange={handleNewKeywordChange}
          placeholder="Add New Keyword"
          error={!!emptyKeywordError}
        />
        <Button
          variant="outlined"
          style={{
            minWidth: "0px",
            borderRadius: "25px",
            margin: "0px 4px 0px 4px",
            height: "35px",
            width: "35px",
          }}
          onClick={handleAddNewKeyword}
          disabled={editSearchTermsLoading}
        >
          +
        </Button>
        {addNewKeywordResponse && (
          <span
            style={{ color: addNewKeywordResponse.success ? "green" : "red" }}
          >
            {addNewKeywordResponse.success
              ? "Keyword added successfully, check back in a few hours"
              : addNewKeywordResponse.message}
          </span>
        )}
      </div>
    </CustomFooterContainer>
  );
};

const RankTracking = ({
  type,
  data,
  sponsoredData,
  uk_data,
  ukSponsoredData,
  currentPeriodicity,
  marketPlace,
}) => {
  const dispatch = useDispatch();
  const { editSearchTermsLoading } = useSelector(advanceBusinessReportSelector);
  const [competitorAsin, setCompetitorAsin] = useState(
    marketPlace === "us"
      ? Object.keys(data?.ranks?.competitors_ranks?.ranks)[0] || []
      : []
  );

  const ranks = useMemo(() => {
    return type === "competitors"
      ? marketPlace === "us"
        ? data.ranks?.competitors_ranks?.ranks[competitorAsin] || []
        : []
      : marketPlace === "us"
      ? data?.ranks?.ranks || []
      : uk_data?.ranks?.ranks;
  }, [data, type, marketPlace, uk_data, competitorAsin]);

  const sponsoredRanks = useMemo(() => {
    return type === "competitors"
      ? marketPlace === "us"
        ? sponsoredData.ranks?.competitors_ranks?.ranks[competitorAsin] || []
        : []
      : marketPlace === "us"
      ? sponsoredData?.ranks?.ranks || []
      : ukSponsoredData?.ranks?.ranks;
  }, [sponsoredData, type, marketPlace, ukSponsoredData, competitorAsin]);

  var uniqueSearchTerms = useMemo(() => {
    return [
      ...new Set(
        ranks?.map((rank) => rank?.search_term),
        ranks?.map((rank) => rank?.search_term)
      ),
    ];
  }, [ranks]);

  uniqueSearchTerms = uniqueSearchTerms.map((searchTerm) => [
    searchTerm,
    ranks?.find((rank) => rank?.search_term === searchTerm)?.search_volume_1w ||
      "-",
    ranks?.find((rank) => rank?.search_term === searchTerm)?.relevancy || "-",
  ]);

  uniqueSearchTerms.sort((a, b) => b[1] - a[1]);

  const apiRef = useGridApiRef();

  const startDate = data ? parseISO(data.start_date) : new Date();
  if (!data) {
    startDate.setDate(startDate.getDate() - 30);
  }
  const endDate = data ? parseISO(data.end_date) : new Date();

  let allDates = useMemo(() => () => data?.allDates || [], [data]);
  if (currentPeriodicity === "day") {
    allDates = eachDayOfInterval({ start: startDate, end: endDate });
  }
  if (currentPeriodicity === "week") {
    allDates = Array.from(
      { length: moment(endDate).diff(moment(startDate), "weeks") + 1 },
      (_, i) => [
        moment(startDate).add(i, "week"),
        moment(startDate)
          .add(i + 1, "week")
          .subtract(1, "day"),
      ]
    );
  }
  if (currentPeriodicity === "month") {
    allDates = Array.from(
      { length: moment(endDate).diff(moment(startDate), "months") + 1 },
      (_, i) => [
        moment(startDate).add(i, "month"),
        moment(startDate).add(i + 1, "month"),
      ]
    );
  }

  function customSortComparator(a, b) {
    let aValue = Number(a.toString().replace("-", ""));
    let bValue = Number(b.toString().replace("-", ""));
    return aValue - bValue;
  }

  const [isEditingRelevancies, setIsEditingRelevancies] = useState(false);
  const [updatedRelevancies, setUpdatedRelevancies] = useState({});
  const [relevancyError, setRelevancyError] = useState("");
  const { store } = useSelector(storeAnalyticsSelector);

  function validateRelevancies(originalRows, modifiedRows) {
    const relevancyTracker = {};

    const allRows = originalRows.map((row) => {
      const modified = modifiedRows.find(
        (modifiedRow) => modifiedRow.term === row.keywords
      );
      return {
        ...row,
        relevancy: modified ? modified.relevancy : row.relevancy,
      };
    });

    const isValid = allRows.reduce((valid, row) => {
      if (!valid) return false;

      const relevancy = row.relevancy;

      if (typeof relevancy === "number" && !isNaN(relevancy)) {
        if (relevancy !== 1 && relevancy !== 2 && relevancy !== 3) {
          setRelevancyError("Invalid values");
          return false;
        }
        if (relevancyTracker[relevancy]) {
          setRelevancyError("Duplicate values");
          return false;
        }
        relevancyTracker[relevancy] = true;
      }

      return true;
    }, true);

    return isValid;
  }

  const handleRelevancySave = (asin) => {
    const modifiedRows = rows.reduce((result, row) => {
      const editedValue = updatedRelevancies[row.id];
      let currentRelevancy =
        editedValue !== undefined ? editedValue : row.relevancy;

      if (!isNaN(currentRelevancy)) {
        currentRelevancy = Number(currentRelevancy);
      }

      if (currentRelevancy !== row.relevancy) {
        result.push({
          child_asin: asin,
          term: row.keywords,
          relevancy: currentRelevancy,
        });
      }
      return result;
    }, []);

    if (modifiedRows.length === 0) {
      setIsEditingRelevancies(false);
      return;
    }

    let isNewRelevanciesValid = validateRelevancies(rows, modifiedRows);
    if (!isNewRelevanciesValid) {
      return;
    }

    setRows((prevRows) =>
      prevRows.map((row) => ({
        ...row,
        relevancy:
          updatedRelevancies[row.id] !== undefined
            ? !isNaN(Number(updatedRelevancies[row.id]))
              ? Number(updatedRelevancies[row.id])
              : updatedRelevancies[row.id]
            : row.relevancy,
      }))
    );

    let rowsToUpdate = modifiedRows.filter(
      (row) => !isNaN(Number(row.relevancy))
    );
    dispatch(
      updateSearchTermsRelevancy({
        store: store,
        child_asin: asin,
        rows: rowsToUpdate,
      })
    );
    setIsEditingRelevancies(false);
  };

  const handleRelevancyCancel = () => {
    setUpdatedRelevancies({});
    setRelevancyError("");
    setIsEditingRelevancies(false);
  };

  const columns = [
    {
      field: "keywords",
      headerName: `Keywords (${uniqueSearchTerms.length})`,
      minWidth: 250,
      renderCell: (params) => {
        const value = params.value;
        return (
          <div
            style={{
              padding: "5px 10px",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      field: "sv1w",
      headerName: "SV (1w)",
      minWidth: 50,
      sortComparator: customSortComparator,
      renderCell: (params) => {
        return (
          <div
            style={{
              padding: "0px 10px",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: "relevancy",
      headerName: "Relevancy",
      minWidth: 150,
      sortComparator: customSortComparator,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ lineHeight: "1", margin: "6px 0 0 0", padding: "0" }}>
            Relevancy
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            {!isEditingRelevancies ? (
              <>
                <Button
                  variant="text"
                  size="small"
                  style={{ padding: "0px", fontSize: "0.7rem" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsEditingRelevancies(true);
                  }}
                >
                  Edit
                </Button>
              </>
            ) : (
              <>
                <div style={{ flexDirection: "row" }}>
                  <Button
                    variant="text"
                    size="small"
                    style={{
                      padding: "0px",
                      fontSize: "0.7rem",
                      textTransform: "none",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRelevancySave(data.child_asin);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    style={{
                      padding: "0px",
                      fontSize: "0.7rem",
                      textTransform: "none",
                      color: "red",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRelevancyCancel();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                {relevancyError && (
                  <span
                    style={{
                      color: "red",
                      height: "14px",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {relevancyError}{" "}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      ),
      renderCell: (params) => {
        const handleRelevancyChange = (e) => {
          if (e.target.value === "") {
            e.target.value = "-";
          } else if (e.target.value.length > 1) {
            e.target.value = e.target.value.slice(1);
          }
          setUpdatedRelevancies((prev) => ({
            ...prev,
            [params.row.id]: e.target.value,
          }));
          if (relevancyError) {
            setRelevancyError("");
          }
        };

        const currentRelevancyValue =
          updatedRelevancies[params.row.id] ?? params.value;

        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isEditingRelevancies || editSearchTermsLoading ? (
              <TextField
                sx={{
                  width: "45px",
                }}
                size="small"
                value={currentRelevancyValue}
                onChange={handleRelevancyChange}
                disabled={editSearchTermsLoading}
              />
            ) : (
              <span>{currentRelevancyValue}</span>
            )}
          </div>
        );
      },
      cellClassName: "noFocusBorder",
      headerClassName: "noFocusBorder",
    },
    ...allDates.map((date, index) => ({
      field: `date_${index}`,
      headerName:
        currentPeriodicity === "day"
          ? format(date, "d MMM")
          : currentPeriodicity === "week"
          ? `${date[0].format("DD MMM")} - ${date[1].format("DD MMM")}`
          : currentPeriodicity === "month"
          ? `${date[0].format("MMM")} ${date[0].format("YYYY")}`
          : "",
      type: "number",
      headerClassName: "position-header",
      width: currentPeriodicity === "day" ? 70 : 140,
      headerAlign: "center",
      renderCell: (params) => {
        const value = params.value.position;
        const valueSponsored = params.value.positionSponsored;
        const backgroundColor = getBackgroundColor(value);
        return (
          <Tooltip
            disableHoverListener={
              currentPeriodicity !== "day" || params.value.tooltip.length === 0
            }
            title={
              <Box>
                {[...params.value.tooltip]
                  .sort((a, b) => {
                    const hourA = moment(a.last_updated_date).format("HH");
                    const hourB = moment(b.last_updated_date).format("HH");
                    return hourA - hourB;
                  })
                  .map((positions, index) => (
                    <Box key={index} sx={{ display: "flex" }}>
                      <Typography sx={{ width: 50 }}>
                        {moment(positions.last_updated_date).format("hh A")}
                      </Typography>
                      <Typography sx={{ width: 20 }}>-</Typography>
                      <Typography>{positions.overall_position}</Typography>
                    </Box>
                  ))}
              </Box>
            }
            arrow
          >
            <div
              style={{
                padding: 5,
                background: backgroundColor,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "600",
                borderLeft: "1px solid #e0e0e0",
                position: "relative",
                // background:
                //   "linear-gradient(to right bottom, " +
                //   "rgba(0, 0, 255, 0.2)" +
                //   " 25%, " +
                //   backgroundColor +
                //   " 25.3%)",
              }}
            >
              {valueSponsored && (
                <>
                  <div
                    style={{
                      display: "inline-block",
                      height: "0",
                      width: "0",
                      borderTop: "40px solid rgb(233 213 255)",
                      borderRight: "40px solid transparent",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "20px",
                      width: "20px",
                      textAlign: "center",
                    }}
                  >
                    {valueSponsored}
                  </span>
                </>
              )}
              {value}
            </div>
          </Tooltip>
        );
      },
    })),
  ];

  function isWeekRangeEqual(startDate, endDate, weekRangeString) {
    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("en-US", { month: "short" });
      return `${day} ${month}`;
    };

    const formattedWeekRange = `${formatDate(startDate)} - ${formatDate(
      endDate
    )}`;
    return formattedWeekRange === weekRangeString;
  }

  const [rows, setRows] = useState([]);
  useEffect(() => {
    try {
      const updatedRows = uniqueSearchTerms.map((searchTerm, rowIndex) => {
        const row = {
          id: rowIndex,
          keywords: searchTerm[0],
          sv1w: searchTerm[1],
          relevancy: searchTerm[2],
        };
        let matchingRank;
        let matchingSponsoredRank;
        allDates.forEach((date, colIndex) => {
          if (currentPeriodicity === "day") {
            matchingRank = ranks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                (r.date === format(date, "dd-MMM") ||
                  r.date === format(date, "d-MMM"))
            );

            matchingSponsoredRank = sponsoredRanks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                (r.date === format(date, "dd-MMM") ||
                  r.date === format(date, "d-MMM"))
            );
          }
          if (currentPeriodicity === "week") {
            let weekStartDate = date[0]._d;
            let weekEndDate = date[1]._d;
            matchingRank = ranks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                isWeekRangeEqual(weekStartDate, weekEndDate, r.date)
            );

            matchingSponsoredRank = sponsoredRanks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                isWeekRangeEqual(weekStartDate, weekEndDate, r.date)
            );
          }
          if (currentPeriodicity === "month") {
            let currentDate = date[0]._d;
            let currentMonth = currentDate.toLocaleString("en-US", {
              month: "short",
            });
            let currentYear = currentDate.getFullYear();
            let currentMonthAndYear = `${currentMonth} ${currentYear}`;
            matchingRank = ranks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                r.date === currentMonthAndYear
            );

            matchingSponsoredRank = sponsoredRanks?.find(
              (r) =>
                r.search_term === searchTerm[0] &&
                r.date === currentMonthAndYear
            );
          }

          row[`date_${colIndex}`] = {
            position: matchingRank?.position || "-",
            tooltip: matchingRank?.tooltip || [],
            positionSponsored: matchingSponsoredRank?.position || null,
          };
        });
        return row;
      });

      if (JSON.stringify(rows) !== JSON.stringify(updatedRows)) {
        setRows(updatedRows);
      }
    } catch (error) {
      console.error("Error updating rows:", error);
    }
  }, [
    uniqueSearchTerms,
    rows,
    allDates,
    ranks,
    sponsoredRanks,
    currentPeriodicity,
  ]);

  setTimeout(() => {
    if (
      apiRef &&
      apiRef.current &&
      apiRef.current.scrollToIndexes &&
      apiRef.current.getAllColumns
    ) {
      apiRef.current.scrollToIndexes({
        colIndex: apiRef.current.getAllColumns
          ? apiRef.current.getAllColumns().length - 1
          : 0,
      });
    }
  }, 100);

  return (
    <div style={{ height: 400, width: "100%" }}>
      {type === "competitors" && marketPlace === "us" && (
        <div>
          <Select
            value={competitorAsin}
            onChange={(e) => setCompetitorAsin(e.target.value)}
            disabled={!competitorAsin}
          >
            {marketPlace === "us"
              ? Object.keys(data?.ranks?.competitors_ranks?.ranks)?.map(
                  (asin, index) => (
                    <MenuItem key={index} value={asin}>
                      {asin}
                    </MenuItem>
                  )
                )
              : Object.keys(uk_data?.ranks?.competitors_ranks?.ranks)?.map(
                  (asin, index) => (
                    <MenuItem key={index} value={asin}>
                      {asin}
                    </MenuItem>
                  )
                )}
          </Select>
          <CompText>
            <CompImageItem
              src={
                `https://images-na.ssl-images-amazon.com/images/I/` +
                (marketPlace === "us"
                  ? data?.ranks?.competitors_ranks?.competitors_asin
                      .filter((comp) => comp.asin === competitorAsin)[0]
                      ?.["images_csv"]?.split(",")[0]
                  : uk_data?.ranks?.competitors_ranks?.competitors_asin
                      .filter((comp) => comp.asin === competitorAsin)[0]
                      ?.["images_csv"]?.split(",")[0])
              }
            />

            <a
              href={`https://www.amazon.com/dp/${competitorAsin}?th=1`}
              target="_blank"
              rel="noreferrer"
            >
              <span>
                {marketPlace === "us"
                  ? data?.ranks?.competitors_ranks?.competitors_asin.filter(
                      (comp) => comp.asin === competitorAsin
                    )[0]?.["brand"]
                  : uk_data?.ranks?.competitors_ranks?.competitors_asin.filter(
                      (comp) => comp.asin === competitorAsin
                    )[0]?.["brand"]}
              </span>
            </a>
            {` brand at a current price of $`}
            <span className="text">
              {marketPlace === "us"
                ? data?.ranks?.competitors_ranks?.competitors_asin.filter(
                    (comp) => comp.asin === competitorAsin
                  )[0]?.["stats"]?.["current"]?.["Marketplace price"]
                : uk_data?.ranks?.competitors_ranks?.competitors_asin.filter(
                    (comp) => comp.asin === competitorAsin
                  )[0]?.["stats"]?.["current"]?.["Marketplace price"]}
            </span>
            {` sold around `}
            <span className="text">
              {marketPlace === "us"
                ? Intl.NumberFormat("en", { notation: "compact" }).format(
                    data?.ranks?.competitors_ranks?.competitors_asin.filter(
                      (comp) => comp.asin === competitorAsin
                    )[0]?.["monthly_sold"] || "x"
                  )
                : Intl.NumberFormat("en", { notation: "compact" }).format(
                    uk_data?.ranks?.competitors_ranks?.competitors_asin.filter(
                      (comp) => comp.asin === competitorAsin
                    )[0]?.["monthly_sold"] || "x"
                  )}
            </span>
            {` units last month.`}
          </CompText>
        </div>
      )}
      <Box height={20} />
      <DataTable
        disableSelectionOnClick
        disableColumnMenu
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        initialState={{
          pinnedColumns: { left: ["keywords", "sv1w", "relevancy"] },
          sorting: {
            sortModel: [{ field: "sv1w", sort: "desc" }],
          },
        }}
        rowsPerPageOptions={[5, 10, 15]}
        sx={{
          "& .position-header .MuiDataGrid-columnHeaderTitle": {
            marginLeft: 4,
          },
        }}
        slots={{
          footer: AddKeywordFooter,
        }}
      />
    </div>
  );
};

export default RankTracking;
