import { Box, Button, Popover, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AuthContext } from "../../../../../contexts/JWTContext";
import {
  deleteProductComment,
  fetchComments,
  upsertProductComment,
} from "../../../../../redux/slices/store_analytics";

export function CommentableHeader({
  colDef,
  asin,
  store,
  dbComments,
  start_date,
  end_date,
}) {
  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comments, setComments] = useState([]);
  const [isEditComment, setEditComment] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [hoveredCommentId, setHoveredCommentId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Load existing comment if available
    if (dbComments && dbComments[asin]) {
      const foundComments = dbComments[asin].filter(
        (c) =>
          c.asin === asin && c.store === store && c.cell === colDef.headerName
      );
      if (foundComments.length > 0) {
        setComments(foundComments);
        // setCommentSaved(true);
      }
    }
  }, [dbComments, asin, store, colDef.headerName]);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleRefresh = () => {
    dispatch(fetchComments({ store, start_date, end_date }));
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNewComment("");
    setEditComment(null);
  };
  const deleteComment = (store, comment) => {
    let updatedComments;
    if (comment.user === user.email) {
      if (comment.id) {
        dispatch(deleteProductComment(store, comment.id, start_date, end_date));
        updatedComments = comments.filter((c) => c.id !== comment.id);
        setComments(updatedComments);
      }
    }
  };
  const editComment = (comment) => {
    if (comment.user === user.email) {
      setEditComment(comment.id);
      setNewComment(comment.text);
    }
  };
  const saveComment = (cellDay, asin, store) => {
    let nowDate = new Date().toLocaleString("sv-SE");
    let commentObj;
    let updatedComments;
    if (isEditComment !== null) {
      // Edit existing comment
      updatedComments = comments.map((comment) => {
        if (comment.id === isEditComment) {
          commentObj = { ...comment, text: newComment, updated_at: nowDate };
          return commentObj;
        }
        return comment;
      });
    } else {
      commentObj = {
        store: store,
        asin: asin,
        cell: cellDay,
        text: newComment,
        user: user.email,
        created_at: nowDate,
        updated_at: nowDate,
      };
      updatedComments = [...comments, commentObj];
    }

    dispatch(upsertProductComment(commentObj, store, start_date, end_date));
    setComments(updatedComments);
    setNewComment("");
    setEditComment(null);
  };

  return (
    <Box display="flex" alignItems="center" style={{ cursor: "pointer" }}>
      <Box onClick={handleOpen}>
        {colDef.headerName}
        {(comments.length > 0 || newComment) && (
          <Box
            style={{
              position: "absolute",
              top: -5,
              right: 5,
              width: 8,
              height: 6,
              // backgroundColor: "salmon",
              // borderRadius: "50%",
              fontSize: 10,
            }}
          >
            {"💬"}
          </Box>
        )}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ marginLeft: -10 }}
      >
        <Box p={2} style={{ minWidth: 200, border: "1px solid lightgray" }}>
          {comments.length > 0 &&
            !isEditComment &&
            comments.map((comment) => (
              <div
                style={{
                  borderBottom: "1px solid lightgray",
                  paddingBottom: "10px",
                  marginBottom: "10px",
                }}
                onMouseEnter={() => setHoveredCommentId(comment.id)}
                onMouseLeave={() => setHoveredCommentId(null)}
              >
                <span style={{ marginLeft: 5 }}> {comment.text} </span>
                <br />
                <span
                  style={{
                    marginLeft: 5,
                    color: "gray",
                    fontSize: 10,
                    fontStyle: "italic",
                  }}
                >
                  {(
                    comment.user.charAt(0).toUpperCase() + comment.user.slice(1)
                  ).replace("@atomic-growth.com", ", ")}
                </span>
                <span
                  style={{
                    color: "gray",
                    fontSize: 10,
                    fontStyle: "italic",
                  }}
                >
                  {comment.updated_at.replace("T", " ")}
                </span>
                {comment.user === user.email &&
                  hoveredCommentId === comment.id && (
                    <div
                      style={{
                        display: "inline-block",
                        alignContent: "flex-end",
                        maxHeigh: 10,
                      }}
                    >
                      <Button
                        onClick={() => editComment(comment)}
                        style={{ maxHeight: 10 }}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => deleteComment(store, comment)}
                        style={{ maxHeight: 10 }}
                      >
                        Delete
                      </Button>
                    </div>
                  )}
              </div>
            ))}
          <div>
            <TextField
              label="Add Comment"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              style={{ marginTop: 10 }}
              size="small"
              fullWidth
              multiline
            />
            <Button
              onClick={() => saveComment(colDef.headerName, asin, store)}
              style={{ marginTop: 0 }}
            >
              Add
            </Button>
            <Button onClick={handleClose} style={{ marginTop: 0 }}>
              Close
            </Button>
            <Button onClick={handleRefresh} style={{ marginTop: 0 }}>
              Refresh
            </Button>
          </div>
        </Box>
      </Popover>
    </Box>
  );
}
